import './App.css';
import HomePage from './pages/HomePage';



function App() {
  return (
    <div className="font-google-sans text-xl">
      <HomePage />
    </div>
  );
}

export default App;
